import { getSlotTimetableRef, getAvailableSlotTimetable } from 'actions/slotTimetable'
import getCollection from './getCollection'
import getFirestore from 'actions/firebase/firestore'
import { runTransaction, doc } from 'firebase/firestore/lite'

const book = async (slotTimetableId, userId, booking, slot = null, fee = null, customer = null, admin = false) =>
  runTransaction(getFirestore(true), async (transaction) => {
    const bookingRef = doc(getCollection())

    const slotTimetable = await getAvailableSlotTimetable(slotTimetableId, admin)
    if (slotTimetable.empty) {
      throw "Booking wasn't available!"
    }

    const slotTimetableData = slotTimetable.docs.pop().data()
    await transaction.update(getSlotTimetableRef(slotTimetableId), { booking: { id: bookingRef.id, ...booking } })
    await transaction.set(bookingRef, {
      ...booking,
      userId,
      slotTimetableId,
      fee,
      customer,
      slot,
      admin,
      start: slotTimetableData.start,
      end: slotTimetableData.end,
    })

    return bookingRef
  })

export default book
